import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {BasicObject} from '@/types';
import {useMemo} from 'react';
import {StyleSheet} from 'react-native';

function useSize() {
  const {screenWidth, screenHeight, designWidth, calcActualSize} =
    useScreenSize();
  const rightIconSize = (14 * screenWidth) / designWidth;
  const itemWidth =
    (screenWidth -
      4 * calcActualSize(theme.paddingSize.l) -
      3 * theme.paddingSize.s) /
    4;
  const itemHeight = (40 * screenWidth) / designWidth;
  const size = useMemo(() => {
    return {
      screenWidth,
      screenHeight,
      designWidth,
      rightIconSize,
      itemWidth,
      itemHeight,
      calcActualSize,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    screenWidth,
    screenHeight,
    designWidth,
    rightIconSize,
    itemWidth,
    itemHeight,
  ]);
  return size;
}

export function useInnerStyle() {
  const size = useSize();

  const amountStyle = StyleSheet.create({
    opacity: {
      opacity: 0.7,
    },
  });

  const allInputHeight = size.calcActualSize(32);
  const compressedInputHeight = size.calcActualSize(16);

  const baseInputStyle = {
    minHeight: size.calcActualSize(allInputHeight),
    maxHeight: size.calcActualSize(allInputHeight),
    height: size.calcActualSize(allInputHeight),
    padding: 0,
    fontWeight: 700,
    ...theme.font.main,
    ...theme.font.fm,
  } as BasicObject;

  const compressedBaseInputStyle = {
    ...baseInputStyle,
    minHeight: size.calcActualSize(compressedInputHeight),
    maxHeight: size.calcActualSize(compressedInputHeight),
    height: size.calcActualSize(compressedInputHeight),
  } as BasicObject;

  const inputStyle = globalStore.isWeb
    ? {
        ...baseInputStyle,
        outlineWidth: '0',
        caretColor: theme.fontColor.main,
      }
    : baseInputStyle;

  const compressedInputStyle = globalStore.isWeb
    ? {
        ...compressedBaseInputStyle,
        outlineWidth: '0',
        caretColor: theme.fontColor.main,
      }
    : baseInputStyle;

  const inputStyles = StyleSheet.create({
    inputContainer: {
      borderBottomWidth: 0,
    },
    container: {
      paddingTop: size.calcActualSize(8),
      paddingBottom: size.calcActualSize(8),
    },
    compressedContainer: {
      paddingTop: size.calcActualSize(8),
      paddingBottom: size.calcActualSize(24),
    },
    error: {
      margin: 0,
      height: 0,
    },
  });

  const selectStyles = StyleSheet.create({
    item: {
      width: size.itemWidth,
      height: size.itemHeight,
    },
    itemMargin: {
      marginLeft:
        (size.screenWidth - theme.paddingSize.l * 4 - size.itemWidth * 4 - 1) /
        3,
    },
    inputWrap: {
      borderWidth: 1,
      borderColor: theme.backgroundColor.grey,
    },
  });

  const balanceStyles = StyleSheet.create({
    cardBg: {
      position: 'absolute',
    },
    container: {
      borderRadius: 8,
      overflow: 'hidden',
    },
    bgBox: {
      width: size.screenWidth - size.calcActualSize(theme.paddingSize.l) * 2,
      height:
        (459 / 1053) *
        (size.screenWidth - size.calcActualSize(theme.paddingSize.l) * 2),
    },
    balanceContainer: {
      paddingHorizontal: size.calcActualSize(20),
      paddingTop: size.calcActualSize(20),
      alignItems: 'center',
    },
    methodContainer: {
      backgroundColor: 'rgba(0,0,0,0.3)',
      paddingHorizontal: size.calcActualSize(20),
    },
  });

  return {
    size,
    amountStyle,
    inputStyle,
    inputStyles,
    selectStyles,
    balanceStyles,
    compressedInputStyle,
  };
}
